

.profile-section{
    text-align: left;
    color: var(--major-color);
}

.section-title{
    display: flex;
    gap:25px;
    align-items: center;
}

.account-info-section{
    display: flex;
    padding: 1.5rem;
    justify-content: space-between;
}

.account-info-text{
    display: flex;
    flex-direction: column;
    gap:12px;
}


.account-info-pfp-container{
    display: flex;
    width: 128px;
    height: 128px;
    align-items: center;
    justify-content: center;
    background:white;
    padding: 1px;
    border-radius: 5px;
}

.account-info-pfp-container:hover{
    cursor: pointer;
}

.account-info-pfp{
    max-width: 128px;
    max-height: 128px;
    object-fit: cover;
}

.account-info-parameter-section{
    display: flex;
    gap:10px;
    align-items: center;
}

.account-info-parameter-edit-icon{
    width: 20px;
    background: var(--type1btn-hoverColor);
    padding: 2px;
}

.account-info-parameter-edit-icon:hover{
    cursor: pointer;
}

.pool-statistics-section{
    width: 100%;
    padding: 1.5rem;
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 100px;
}

.pool-statistics-group{
    display: flex;
    flex-direction: column;
    gap:4px;
}

.pool-analytics-section{
    display: flex;
    flex-direction: column;
    gap:12px;
    padding: 1.5rem;
}

.pool-analytics-parameter-section{
    display: flex;
    gap:10px;
    align-items: center;
}

.incentive-programs-summary-section{
    display: flex;
    flex-direction: column;
    gap:12px;
    padding: 1.5rem;
}

.incentive-programs-summary-parameter-section{
    display: flex;
    gap:10px;
    align-items: center;
}

.dropdown-type1{
    padding: 0.2rem;
    border-radius: 5px;
}

.br-container{
    border-style: solid;
    border-width: 1px;
    border-color: rgba(256, 256, 256, 0.2);
    border-radius: 5px;
    margin: 1rem;
    height: 200px;
    overflow-y: auto;
}

.br-item{
    margin: 0.5rem;
    padding: 0.5rem;
    border: solid 1px rgba(256, 256, 256, 0.05);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background: white;
    color: black;
}

.br-item:hover{
    background: rgba(256, 256, 256, 0.9);

}

.br-details{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.br-buttons{
    display: flex;
    align-items: flex-end;
    gap: 10px;
}