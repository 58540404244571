/* src/components/Header.css */

/* Header styles */
.header {
    background-color: var(--major-backgroundColor);
    color: white;
    padding: 1rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  
  /* Navigation styles */
  .nav {
    display: flex;
    justify-content: center; /* Horizontally center the navigation */
  }
  
  /* Navigation list styles */
  .nav-list {
    list-style: none;
    padding: 0;
    display: flex; /* Make the list items horizontal */
  }
  
  /* Individual navigation item styles */
  .nav-item {
    margin-right: 20px; /* Add space between navigation items */
  }
  
  /* Style the links within the navigation items */
  .nav-item a {
    text-decoration: none;
    color: var(--major-color);
    /*font-weight: bold;*/
    
    font-size: 1.2rem;
    transition: color 0.3s ease;
    padding: 0.8rem;
  }
  
  .nav-item a:hover {
    background-color: rgb(0,0,0,0.2);
  }
  
  .logo {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 3rem;
  }


  
  .logo img {
    height: 40px; /* Adjust the height as needed */
  }

  .preview-badge{
    border-radius: 5px;
    border-style: solid;
    background: burlywood;
    border-color: transparent;
    padding-left: 4px;
    padding-right: 4px;
    color: black;
  }

  .wallet-connect {
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:15px;
  }

  .wallet-connect .login-text{
    color: var(--major-color);
  }

  .wallet-connect .login-text:hover{
    cursor: pointer;
    color: var(--main-borderColor);
  }

  .wallet-connect button {
    background-color: var(--type1btn-color); /* Use your theme color */
    color: var(--type1btn-fontColor); /* Use your theme color */
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .wallet-connect button:hover {
    background-color: var(--type1btn-hoverColor); /* Use your theme color */
  }